.pagination > ul {
    margin-bottom: 0;
}

.page-item .page-link {
    color: #0a8dae;
}
.page-item.active .page-link {
    z-index: inherit;
    background-color: #0a8dae !important;
    border-color: #0a8dae !important;
}

#equipmentsFilters {
    background-color: #eee;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

#equipmentsFilters .form-control {
    font-size: inherit;
    padding: 0 0.1rem;
}

.table td, .table th, .table thead th {
    border: 0px !important;
}

.tab-content {
    padding: 1em;
    background: rgb(245, 245, 245);
    background: linear-gradient(180deg, rgba(245, 245, 245, 1) 50%, rgba(250, 250, 250, 1) 100%);
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: whitesmoke;
    border-color: #94bfc5 #94bfc5 whitesmoke;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: whitesmoke;
    font-weight: bold;
    border-color: #94bfc5 #94bfc5 whitesmoke;
}

.nav-tabs {
    border-bottom: 1px solid #94bfc5;
}

.mark {
    background-color: tomato;
    color: white;
    border: 1px solid red;
    padding: 0 0.1em;
}

.img-thumbnail.img-max {
    max-width: 120px;
    max-height: 120px;
    width: 100%;
}

.dropdown-menu {
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.custom-switch, .custom-switch label:hover {
    cursor: pointer;
}

.table-striped tbody tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
}

.navbar-nav {
    width: 100%;
}

.navbar-nav a {
    color: #0a8dae;
    border: 1px solid transparent;
    border-radius: 3px;
    text-decoration: none;
}

.navbar-nav a:hover:not(.logo-item):not(.dropdown-item):not(.cart-item):not(.selected) {
    border: 1px solid #0a8dae91;
    border-radius: 3px;
    background-color: #0a8dae17;
    text-decoration: none;
    color: #0a8dae;
}

.navbar-nav a:not(.logo-item).selected {
    border: 1px solid #0a8dae;
    border-radius: 3px;
    background-color: #0a8dae17;
    text-decoration: none;
    cursor: default;
}

a.nav-link, a.nav-link:hover, a.nav-link .active {
    color: #0a8dae;
}

.modal-content {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #ffffff;
}

.modal-header {
    background-color: floralWhite;
}

.modal-backdrop {
    display: none;
}

.modal {
    background: rgba(0, 0, 0, 0.5);
}

.modal-dialog.fullscreen {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.modal-dialog.fullscreen .modal-content, .modal-dialog.fullscreen .modal-header {
    border: 0;
    border-radius: unset;
}

.modal-dialog.fullscreen .modal-header {
    background-color: var(--secondary);
    border-bottom: 2px solid #fff;
}

.modal-dialog.fullscreen .modal-title {
    color: #fff;
}

.modal-dialog.fullscreen .modal-body {
    overflow: auto;
}

.bg-info {
    background-color: #0a8dae !important;
}